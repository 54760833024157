<template>
    <modal @close="close">
        <template #title>{{product.title}}</template>
        <template #interactive><button-b class="bg-primary" @click.native="addAndSave">{{ $t('dashboard.save') }}</button-b></template>
        <template #body>
            <input-normal v-model="selection.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.selection_type')">
                <template #title>{{ $t('dashboard.title') }}</template>
            </input-normal>
            <checkbox class="mt-3" v-model="selection.excludefromMinOrder">{{ $t('dashboard.exclude_price_selections')}}</checkbox>
            <draggable
                v-model="selection._data.options"
                handle=".drag"
                ghost-class="opacity-30">
            <option-input v-for="(option, index) in selection.options" :key="option.uuid" :option="option" :index="index" @remove="removeOption(option.uuid)" />
            </draggable>
            <div class="mt-10">
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center">
                        <button @click="addOptions" type="button" class="inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm leading-5 font-bold rounded-full text-gray-600 bg-white hover:bg-gray-50 focus:outline-none">
                            <svg class="-ml-1.5 mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                            </svg>
                            <span>{{ $t('dashboard.new_option') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../ui/InputNormal.vue'
import ButtonB from '../ui/ButtonB.vue'
import Modal from '../dashboard/Modal.vue'
import { Product, ProductSelection } from 'delivery-boosting-2021-model'
import OptionInput from '../dashboard/OptionInput.vue'
import draggable from 'vuedraggable'
import Checkbox from '@/components/ui/Checkbox.vue'

export default {
    name: 'EditSelection',
    data () {
        return {
            selectionTitle: '',
            optionTitle: ''
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        addOptions () {
            this.selection.addProductOptions({
                price: [
                    {
                        method: 'delivery',
                        currency: 'EUR',
                        amount: 0
                    },
                    {
                        method: 'pick-up',
                        currency: 'EUR',
                        amount: 0
                    }
                ]
            })
        },
        removeOption (uuid) {
            console.log(this)
            this.selection.removeProductOptions(uuid)
        },
        async addAndSave () {
            this.close()
        }
    },
    props: {
        product: {
            type: Product
        },
        selection: {
            type: ProductSelection,
            default () {
                return new ProductSelection()
            }
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        OptionInput,
        draggable,
        Checkbox
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
