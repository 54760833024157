<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.add_selection') }}</template>
        <template #interactive><button-b class="bg-primary" @click.native="addAndSave">{{ $t('dashboard.add') }}</button-b></template>
        <template #body>
            <input-normal v-model="selection.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.selection_type')">
                <template #title>{{ $t('dashboard.title') }}</template>
            </input-normal>
            <checkbox class="mt-3" v-model="selection.excludefromMinOrder">{{ $t('dashboard.exclude_price_selections')}}</checkbox>
            <draggable
                v-model="selection._data.options"
                handle=".drag"
                ghost-class="opacity-30">
                <transition-group name="list" tag="p">
                <option-input v-for="(option, index) in selection.options" :key="option.uuid" :option="option" :index="index" @remove="removeOption(option.uuid)" />
                </transition-group>
            </draggable>
            <div class="mt-10">
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center">
                        <button @click="addOptions" type="button" class="inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm leading-5 font-bold rounded-full text-gray-600 bg-white hover:bg-gray-50 focus:outline-none">
                            <svg class="-ml-1.5 mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                            </svg>
                            <span>{{ $t('dashboard.new_option') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../ui/InputNormal.vue'
import ButtonB from '../ui/ButtonB.vue'
import Modal from '../dashboard/Modal.vue'
import { Product, ProductSelection } from 'delivery-boosting-2021-model'
import OptionInput from '../dashboard/OptionInput.vue'
import draggable from 'vuedraggable'
import Checkbox from '@/components/ui/Checkbox.vue'

export default {
    name: 'AddSelection',
    data () {
        return {
            selectionTitle: '',
            optionTitle: '',
            selection: new ProductSelection()
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        addOptions () {
            this.selection.addProductOptions({
                price: [
                    {
                        method: 'delivery',
                        currency: 'EUR',
                        amount: 0
                    },
                    {
                        method: 'pick-up',
                        currency: 'EUR',
                        amount: 0
                    }
                ]
            })
        },
        removeOption (uuid) {
            console.log(this)
            this.selection.removeProductOptions(uuid)
        },
        async addAndSave () {
            console.log(this.product)
            this.product.addSelection(this.selection.toPlainObject())
            /* this.product.addSelection({
                title: this.selectionTitle,
                options: [{
                    title: this.optionTitle,
                    price: [{
                        method: 'delivery',
                        amount: this.deliveryPrice,
                        currency: 'EUR'
                    }, {
                        method: 'pick-up',
                        amount: this.pickupPrice,
                        currency: 'EUR'
                    }]

                }]
            }) */
            // await this.$store.state.catalog.commit()
            this.close()
        }
    },
    props: {
        product: {
            type: Product
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        OptionInput,
        draggable,
        Checkbox
    }
}
</script>

<style src="@/assets/css/multiselect.css">
.list-enter-active, .list-leave-active {
    transition: all 1s;
    }
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}

.list-leave-active {
    max-height: 136px;
}

.list-leave-to{
    max-height: 0;
}

.list-move {
    transition: transform 1s;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}</style>
