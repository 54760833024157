<template>
  <prism-editor class="my-editor rounded-md" v-model="code" :highlight="highlighter" line-numbers></prism-editor>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
    components: {
        PrismEditor
    },
    props: ['value'],
    data: () => ({ code: '' }),
    watch: {
        value: {
            immediate: true,
            handler (val) {
                this.code = this.value || `
// CUSTOM PRICE CALCULATION - EXAMPLE
var a = PRODUCT.title.length;
var b = UPGRADES.length;
var c = LINE_ITEM.quantity;

ret(Math.pow(a, b) * c)
                `
            }
        },
        code () {
            this.$emit('input', this.code)
        }
    },
    methods: {
        highlighter (code) {
            return highlight(code, languages.js) // returns html
        }
    }
}
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
