<template>
<div class="mt-5">
    <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex items-center justify-between">
                <span class="pr-3 bg-white text-sm text-gray-500">
                {{ $t('dashboard.size') }} {{index + 1}} {{ (index === 0)? `(${$t('dashboard.default')})` : '' }}
                </span>
                <div class="test">
                    <button v-if="index !== 0" @click="$emit('remove')" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm text-primary hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
                        <span class="sr-only">{{ $t('dashboard.delete') }}</span>
                        <icon-trash class="h-5 w-5"/>
                    </button>
                    <button type="button" :class="{'rounded-l-md': index === 0}" class="drag relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm text-gray-600 hover:bg-gray-50 focus:z-10 focus:outline-none">
                        <span class="sr-only">{{ $t('dashboard.delete') }}</span>
                        <icon-dots class="h-5 w-5"/>
                    </button>
                </div>
            </div>
        </div>
    <div class="grid gap-4 grid-cols-8 mt-5">
        <div class="col-span-4">
            <input-normal v-model="size.title"
                inputtype="text"
                inputname="name"
                :inputplaceholder="$t('dashboard.small')"
            >
            <template #title>{{ $t('dashboard.size') }}</template>
            </input-normal>
        </div>
        <div class="col-span-2">
            <input-price v-model="deliveryPrice"
                inputtype="text"
                inputname="name"
            >
            <template #title>{{$t('dashboard.delivery')}}</template>
            </input-price>
        </div>
        <div class="col-span-2">
            <input-price v-model="pickupPrice"
                inputtype="text"
                inputname="name"
                inputid="name2"
            >
            <template #title>{{$t('dashboard.pick-up')}}</template>
            </input-price>
        </div>
    </div>
    </div>
</template>

<script>
import InputNormal from '@/components/ui/InputNormal.vue'
import InputPrice from '@/components/ui/InputPrice.vue'
import IconTrash from '../icons/IconTrash.vue'
import IconDots from '../icons/IconDots.vue'
import { Size } from 'delivery-boosting-2021-model'
export default {
    components: {
        InputNormal,
        InputPrice,
        IconTrash,
        IconDots
    },
    props: {
        size: {
            type: Size
        },
        index: {
            type: Number
        }
    },
    computed: {
        deliveryPrice: {
            get () {
                return (this.size.getPriceByMethod('delivery') || { amount: 0 }).amount
            },
            set (val) {
                if (this.size.price.find(p => p.method === 'delivery')) {
                    this.size.getPriceByMethod('delivery').amount = val
                } else {
                    this.size.addPrice({
                        method: 'delivery',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        },
        pickupPrice: {
            get () {
                return (this.size.getPriceByMethod('pick-up') || { amount: 0 }).amount
            },
            set (val) {
                if (this.size.price.find(p => p.method === 'pick-up')) {
                    this.size.getPriceByMethod('pick-up').amount = val
                } else {
                    this.size.addPrice({
                        method: 'pick-up',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        }
    }
}
</script>
