var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v(_vm._s(_vm.$t('dashboard.status')))]),_c('multiselect',{attrs:{"options":[ 'ONLINE', 'OFFLINE', 'TIMED' ],"placeholder":_vm.$t('dashboard.choose_status'),"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":false},on:{"input":function($event){_vm.availability = _vm.product.availability}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.$t(("dashboard.status_" + option))))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(("dashboard.status_" + option))))]}}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2),_c('transition',{attrs:{"name":"slide-fade"}},[_c('status-timed',{directives:[{name:"show",rawName:"v-show",value:(_vm.state == 'TIMED'),expression:"state == 'TIMED'"}],attrs:{"availability":_vm.availability}})],1),_c('label',{staticClass:"block text-sm font-bold mb-1 mt-5"},[_vm._v(_vm._s(_vm.$t('dashboard.availability')))])],1),_c('div',[_c('multiselect',{attrs:{"options":[ 'ALWAYS', 'NEVER', 'TIMED' ],"placeholder":_vm.$t('dashboard.choose_availability'),"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":false},on:{"x-input":function($event){_vm.availability = _vm.product.availability}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.$t(("dashboard.available_" + option))))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(("dashboard.available_" + option))))]}}]),model:{value:(_vm.available),callback:function ($$v) {_vm.available=$$v},expression:"available"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2),_c('transition',{attrs:{"name":"slide-fade"}},[_c('time-window',{directives:[{name:"show",rawName:"v-show",value:(_vm.available == 'TIMED'),expression:"available == 'TIMED'"}],attrs:{"availability":_vm.availability}})],1)],1),(_vm.restaurant.locations.length > 1)?_c('div',{staticClass:"mt-3"},[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v(_vm._s(_vm.$t('dashboard.locations_choose')))]),_c('multiselect',{attrs:{"options":_vm.restaurant.locations,"track-by":"uuid","label":"generalTitle","placeholder":'Standorte wählen',"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":true},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(option.generalTitle))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.generalTitle))]}}],null,false,124507890),model:{value:(_vm.locations),callback:function ($$v) {_vm.locations=$$v},expression:"locations"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }