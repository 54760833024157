<template>
    <dashboard-sidebar>
        <template #over-content>
            <div class="flex items-center justify-between pb-10">
                <div class="flex-1 min-w-0">
                    <div class="flex items-center">
                        <div class="mr-4">
                            <span class="px-4 py-2 bg-gray-500 text-gray-500 bg-opacity-20 rounded font-bold">
                            {{product.no}}
                            </span>
                        </div>
                        <h1 class="text-gray-900">{{product.title}}</h1>
                        <div class="ml-4" v-for="category in categories" :key="category.uuid">
                            <badge settings="bg-primary transparent">{{category.title}}</badge>
                        </div>
                    </div>
                </div>
                <div class="mt-4 flex md:mt-0 md:ml-4">
                    <AppSection position="FOOD_DETAIL_BUTTON" :product="product" />
                    <button-a @click.native="$router.back()" settings="bg-primary mr-4 cursor-pointer">
                        <icon-chevron-left height="24"/>
                        {{ $t('dashboard.back') }}
                    </button-a>
                    <button-a @click.native="deleteModal = true" settings="bg-primary mr-4 cursor-pointer">
                        {{ $t('dashboard.delete') }}
                    </button-a>
                    <button-a @click.native="addAndSave" settings="bg-primary cursor-pointer">
                        <icon-check height="24"/>
                        {{ $t('dashboard.save') }}
                    </button-a>
                    <transition name="slide-fade">
                    <delete-fooddetailproduct v-if="deleteModal === true" @close="deleteModal = false" :uuid="product.uuid" :title="product.title"/>
                    </transition>
                </div>
            </div>
        </template>
        <template #content>
            <div class="grid grid-cols-10 grid-flow-col gap-10">
                <div class="col-span-6">
                    <card-box>
                        <h4 class="mb-5">{{ $t('dashboard.details') }}</h4>
                        <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-2">
                                <input-normal v-model="product.no" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_number')">
                                    <template #title>{{ $t('dashboard.placement') }}</template>
                                </input-normal>
                            </div>
                            <div class="col-span-4">
                                <input-normal v-model="product.title" inputtype="text" inputname="name" inputid="name1" :inputplaceholder="$t('dashboard.title')">
                                <template #title>{{ $t('dashboard.title') }}</template>
                                </input-normal>
                            </div>
                            <div class="col-span-6">
                                <input-textarea v-model="product.description" :inputplaceholder="$t('dashboard.description')">
                                <template #title>{{ $t('dashboard.description') }}</template>
                                </input-textarea>
                            </div>
                            <div class="col-span-6">
                                <div class="grid grid-cols-4">
                                    <checkbox v-model="product.properties.vegetarian" checkboxname="Vegetarisch"><span class="text-base">{{ $t('dashboard.vegetarian') }}</span></checkbox>
                                    <checkbox v-model="product.properties.vegan" class="ml-10" checkboxname="Vegan"><span class="text-base">{{ $t('dashboard.vegan') }}</span></checkbox>
                                    <checkbox v-model="product.properties.glutenFree" class="ml-10" checkboxname="GlutenFrei"><span class="text-base">{{ 'Glutenfrei' }}</span></checkbox>
                                    <checkbox v-model="product.properties.basic" class="ml-10" checkboxname="Basisch"><span class="text-base">{{ 'Basisch' }}</span></checkbox>
                                </div>
                            </div>
                        </div>
                    </card-box>
                </div>
                <div class="col-span-6">
                    <card-box>
                        <h4 class="pb-5">{{ $t('dashboard.selections') }}</h4>
                        <draggable
                                v-model="product._data.selections"
                                class=""
                                handle=".drag"
                                ghost-class="opacity-30"
                                >
                        <transition-group name="list" tag="p">
                        <div v-for="selection in product.selections" :key="selection.uuid" class="mb-10">
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div class="w-full border-t border-gray-300"></div>
                                </div>
                                <div class="relative flex items-center justify-between">
                                    <span class="pr-3 bg-white text-lg font-bold text-gray-600">
                                    {{selection.title}}
                                    </span>
                                    <div class="test">
                                        <button @click="selectionModal = selection.uuid" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm text-green hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
                                            <span class="sr-only">{{$t('dashboard.edit')}}</span>
                                            <icon-cog class="h-5 w-5"/>
                                        </button>
                                        <button @click="product.removeSelection(selection.uuid)" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm text-primary hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
                                            <span class="sr-only">{{$t('dashboard.remove')}}</span>
                                            <icon-trash class="h-5 w-5"/>
                                        </button>
                                        <button type="button" class="text-gray-600 drag cursor-move relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm text-primary hover:bg-gray-50 focus:z-10 focus:outline-none">
                                            <span class="sr-only">{{$t('dashboard.move')}}</span>
                                            <icon-dots class="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- table -->
                            <div class="flex flex-col mt-4">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="overflow-hidden">
                                        <table class="min-w-full ">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-500">
                                                        {{$t('dashboard.option')}}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-500 text-center">
                                                        {{$t('dashboard.delivery_extr_price')}}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-500 text-center">
                                                        {{$t('dashboard.pick-up_extr_price')}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="option in selection.options" :key="option.uuid" >
                                                <tr class="bg-white">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-600">
                                                        {{option.title}}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-center">
                                                        {{ (option.getPriceByMethod('delivery'))? $n((option.getPriceByMethod('delivery').amount)/100, 'currency') : '' }}
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-center text-gray-500">
                                                        {{ (option.getPriceByMethod('pick-up'))? $n((option.getPriceByMethod('pick-up').amount)/100, 'currency') : '' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div><!-- END: table -->
                            <transition name="slide-fade">
                            <edit-selection v-if="selectionModal == selection.uuid" @close="selectionModal = ''" :selection="selection" :product="product"/>
                            </transition>
                        </div>
                        </transition-group>
                        </draggable>
                        <div class="mb-10">
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div class="w-full border-t border-gray-300"></div>
                                </div>
                                <div class="relative flex justify-center">
                                    <button type="button" @click="showModal" class="inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm leading-5 font-bold rounded-full text-gray-600 bg-white hover:bg-gray-50 focus:outline-none">
                                        <svg class="-ml-1.5 mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                        <span>{{$t('dashboard.selection')}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                        <add-selection v-if="isModalVisible" @close="closeModal" :product="product"/>
                        </transition>
                        <h4 class="mt-10">{{ $t('dashboard.extras') }}</h4>
                        <div class="mt-5">
                                <div class="flex cursor-pointer mb-5">
                                    <button-l @click.native="chooseAllUpgrades">Alle Extras auswählen</button-l>
                                </div>
                            <div class="flex mt-1">
                                <div class="w-4/5">
                                    <multiselect
                                    v-model="valueUpgrades"
                                    :tag-placeholder="$t('dashboard.add_additives')"
                                    :placeholder="$t('dashboard.search_additives')"
                                    label="title"
                                    track-by="uuid"
                                    :options="$store.state.catalog.upgrades"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag"
                                    :deselectLabel="$t('dashboard.remove')"
                                    :selectedLabel="$t('dashboard.selected')"
                                    :selectLabel="$t('dashboard.select')"
                                    >
                                    <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                    </multiselect>
                                </div>
                                <div class="w-1/5 pl-4 flex items-center justify-end">
                                    <button-l @click.native="isUpgradeModal = true" settings="line cursor-pointer">{{ $t('dashboard.add') }}</button-l>
                                </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                        <add-extra v-if="isUpgradeModal" @close="isUpgradeModal = false"/>
                        </transition>
                        <h4 class="mt-10">{{ $t('dashboard.additives') }}</h4>
                        <div class="mt-5">
                            <div class="flex mt-1">
                                <div class="w-4/5">
                                    <multiselect
                                    v-model="valueAdditives"
                                    :tag-placeholder="$t('dashboard.add_additives')"
                                    :placeholder="$t('dashboard.search_additives')"
                                    label="title"
                                    track-by="uuid"
                                    :options="$store.state.catalog.additives"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag"
                                    :deselectLabel="$t('dashboard.remove')"
                                    :selectedLabel="$t('dashboard.selected')"
                                    :selectLabel="$t('dashboard.select')">
                                    <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                    </multiselect>
                                </div>
                                <div class="w-1/5 pl-4 flex items-center justify-end">
                                    <button-l @click.native="isAdditiveModal = true" settings="line cursor-pointer">{{ $t('dashboard.add') }}</button-l>
                                </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                        <add-additive v-if="isAdditiveModal" @close="isAdditiveModal = false"/>
                        </transition>
                                <!--<label class="block text-sm font-bold mb-1" for="groupSelect">{{ $t('dashboard.choose_group') }}</label>
                                <div class="flex">
                                    <div class="w-3/4">
                                        <multiselect
                                        id="groupSelect"
                                        v-model="valueExtraGroup"
                                        :options="[ 'Pizza', 'Salate' ]"
                                        :placeholder="$t('dashboard.choose_group')"
                                        :deselectLabel="$t('dashboard.remove')"
                                        :selectedLabel="$t('dashboard.selected')"
                                        :selectLabel="$t('dashboard.select')">
                                        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                        </multiselect>
                                    </div>
                                    <div class="w-1/4 pl-4">
                                        <button-b class="bg-primary w-full justify-center transparent">{{ $t('dashboard.load_groups') }}</button-b>
                                    </div>
                                </div>-->
                                <!--<hr class="mt-5"> -->
                                <!--
                                <div class="mt-5">
                                    <label class="block text-sm font-bold mb-1" for="extraGroup1">{{ $t('dashboard.ingredients') }}</label>
                                    <div class="flex mt-1">
                                        <div class="w-1/5 pl-4 flex items-center justify-end">
                                            <button-l href="#" settings="line">{{ $t('dashboard.add') }}</button-l>
                                        </div>
                                    </div>
                                </div>
                                 -->
                        <h4 class="mt-10">{{ $t('dashboard.advanced_settings') }}</h4>
                        <div class="mt-5">
                            <input-normal v-model="product.articleNumber" inputtype="text" inputname="name" inputid="articleNum" :inputplaceholder="$t('dashboard.example_articleNumber')">
                                <template #title>{{ $t('dashboard.articleNumber') }}</template>
                            </input-normal>
                            <checkbox class="mt-5" v-model="product.excludefromMinOrder">{{ $t('dashboard.exclude_price')}}</checkbox>
                        </div>
                        <div class="mt-5">
                            <div class="flex my-1">
                                <toggle class="mr-5 my-2" v-model="product.custom" ><span class="font-bold ml-4">{{$t('dashboard.custom_pricing')}}</span></toggle>
                            </div>
                            <ScriptEditor v-if="product.custom" v-model="priceCalculation" />
                        </div>
                    </card-box>
                        <metafield-definition class="mt-10"/>
                </div>
                <div class="col-span-4 row-span-3">
                    <card-box>
                        <h4 class="mb-5">{{ $t('dashboard.configuration') }}</h4>
                        <div class="grid grid-flow-row grid-cols-1 gap-6">
                            <product-availability :availability="availability" :product="product"/>
                            <div>
                                <label class="block text-sm font-bold mb-1">{{ $t('dashboard.category') }}</label>
                                <multiselect
                                v-model="categories"
                                @select="selectCategory"
                                @remove="removeCategory"
                                :options="catalog.categories"
                                track-by="uuid"
                                label="title"
                                :placeholder="'Kategorie/n wählen'"
                                :deselectLabel="$t('dashboard.remove')"
                                :selectedLabel="$t('dashboard.selected')"
                                :selectLabel="$t('dashboard.select')"
                                :multiple="true">
                                <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                <template slot="option" slot-scope="{ option }">{{ option.title }}</template>
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.title }}</strong></template>
                                </multiselect>
                            </div>
                        </div>
                        <h4 class="mt-10">{{ $t("dashboard.price") }}</h4>
                        <draggable
                            v-model="product._data.sizes"
                            class=""
                            handle=".drag"
                            ghost-class="opacity-30"
                            >
                            <transition-group name="list" tag="p">
                            <product-price v-for="(size, index) in product.sizes" :key="size.uuid" :size="size" :index="index" @remove="removeSize(size.uuid)" />
                            </transition-group>
                        </draggable>
                        <div class="mt-5">
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div class="w-full border-t border-gray-300"></div>
                                </div>
                                <div class="relative flex justify-center">
                                    <button type="button" @click="addSize" class="inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm leading-5 font-bold rounded-full text-gray-600 bg-white hover:bg-gray-50 focus:outline-none">
                                        <svg class="-ml-1.5 mr-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                        <span>{{$t('dashboard.add_size')}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </card-box>
                </div>
            </div>
        </template>
    </dashboard-sidebar>
</template>

<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import CardBox from '@/components/dashboard/CardBox.vue'
import InputNormal from '@/components/ui/InputNormal.vue'
import InputTextarea from '@/components/ui/InputTextarea.vue'
import Multiselect from 'vue-multiselect'
import Badge from '@/components/ui/Badge.vue'
import ButtonA from '@/components/ui/ButtonA.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import IconDots from '@/components/icons/IconDots.vue'
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
// import ButtonB from '@/components/ui/ButtonB.vue'
import ButtonL from '@/components/ui/ButtonL.vue'
import ProductPrice from '@/components/dashboard/ProductPrice.vue'
import ScriptEditor from '@/components/dashboard/ScriptEditor.vue'
import AddSelection from '@/components/modals/AddSelection.vue'
import IconTrash from '@/components/icons/IconTrash.vue'
import IconCog from '@/components/icons/IconCog.vue'
import Vue from 'vue'
import draggable from 'vuedraggable'
import AddAdditive from '@/components/modals/AddAdditive.vue'
import AddExtra from '@/components/modals/AddExtra.vue'
import EditSelection from '@/components/modals/EditSelection.vue'
import ProductAvailability from '@/components/dashboard/ProductAvailability.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import DeleteFooddetailproduct from '@/components/modals/DeleteFooddetailproduct.vue'
import Toggle from '@/components/ui/Toggle.vue'
import MetafieldDefinition from '@/components/dashboard/MetafieldDefinition.vue'

export default {
    name: 'FoodDetail',
    data () {
        return {
            test: [],
            isModalVisible: false,
            valueAvailable: null,
            valueStatus: null,
            valueCategory: null,
            valueExtraGroup: null,
            valueExtraGroup1: null,
            valueAdditiveIdx: 0,
            isAdditiveModal: false,
            valueUpgradeIdx: 0,
            isUpgradeModal: false,
            selectionModal: '',
            deleteModal: false

        }
    },
    watch: {
        sizes: {
            handler () {
                if (this.sizes.length === 0) {
                    this.addSize()
                }
            },
            immediate: true
        }
    },
    methods: {
        selectCategory (option) {
            option.addProduct(this.product.uuid)
        },
        removeCategory (option) {
            option.removeProduct(this.product.uuid)
        },
        showModal () {
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        async addAndSave () {
            if (this.articleNumbers.includes(this.product.articleNumber)) {
                alert(this.$t('dashboard.duplicated_skus'))
            } else {
                await this.$store.state.catalog.commit()
                this.$router.back()
            }
        },
        addSize () {
            this.product.addSize({
                price: [
                    {
                        method: 'delivery',
                        currency: 'EUR',
                        amount: 0
                    },
                    {
                        method: 'pick-up',
                        currency: 'EUR',
                        amount: 0
                    }
                ]
            })
        },
        removeSize (uuid) {
            console.log(this)
            this.product.removeSize(uuid)
        },
        chooseAllUpgrades () {
            this.product.upgrades = this.$store.state.catalog.upgrades.map(u => u.uuid)
        }
    },
    components: {
        ScriptEditor,
        DashboardSidebar,
        CardBox,
        InputNormal,
        InputTextarea,
        Multiselect,
        Badge,
        ButtonA,
        IconCheck,
        IconChevronLeft,
        // ButtonB,
        ButtonL,
        ProductPrice,
        AddSelection,
        IconTrash,
        IconCog,
        AddAdditive,
        AddExtra,
        draggable,
        IconDots,
        EditSelection,
        ProductAvailability,
        Checkbox,
        DeleteFooddetailproduct,
        Toggle,
        MetafieldDefinition
    },
    computed: {
        categories: {
            get () {
                return this.catalog.categories.filter(x => x.products.includes(this.product.uuid))
            }
        },
        product () {
            return Vue.observable(this.$store.state.catalog.getProductByUuid(this.$route.params.productId))
        },
        sizes () {
            return this.product.sizes
        },
        availability () {
            console.log({ av: this.product.availability })
            return this.product.availability
        },
        priceCalculation: {
            set (val) {
                this.$set(this.product.properties, 'priceCalculation', val)
            },
            get () {
                return this.product.properties.priceCalculation || null
            }
        },
        valueAdditives: {
            set (val) {
                console.log(this.valueAdditiveIdx++)
                this.$set(this.product, 'additives', val.map(a => a.uuid))
            },
            get () {
                console.log(this.valueAdditiveIdx)
                return this.$store.state.catalog.additives.filter(a => this.product.additives.includes(a.uuid))
            }
        },
        valueUpgrades: {
            set (val) {
                console.log(this.valueUpgradeIdx++)
                this.$set(this.product, 'upgrades', val.map(a => a.uuid))
            },
            get () {
                console.log(this.valueUpgradeIdx)
                return this.$store.state.catalog.upgrades.filter(a => this.product.upgrades.includes(a.uuid))
            }
        },
        catalog () {
            return this.$store.state.catalog
        },
        articleNumbers () {
            if (!this.$store.state.restaurant.allowMultipleSKUs) {
                return this.$store.state.catalog.products.filter(p => p.uuid !== this.product.uuid).map(p => p.articleNumber).filter(p => p != null && typeof p !== 'undefined' && p !== '')
            } else {
                return []
            }
        }
    },
    async beforeDestroy () {
        await this.$store.dispatch('fetchRestaurant')
    }
}

</script>

<style src="@/assets/css/multiselect.css">
.list-enter-active, .list-leave-active {
    transition: all 1s;
    }
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}

.list-leave-active {
    max-height: 136px;
}

.list-leave-to{
    max-height: 0;
}

.list-move {
    transition: transform 1s;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
