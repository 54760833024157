<template>
    <div class="mt-10">
        <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex items-center justify-between">
                <span class="pr-3 bg-white text-sm text-gray-500">
                Option {{index + 1}}
                </span>
                <div class="test">
                    <button @click="$emit('remove')" type="button" class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm text-primary hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
                        <span class="sr-only">{{ $t('dashboard.delete') }}</span>
                        <icon-trash class="h-5 w-5"/>
                    </button>
                    <button type="button" :class="{'rounded-l-md': index === 0}" class="drag relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm text-gray-600 hover:bg-gray-50 focus:z-10 focus:outline-none">
                        <span class="sr-only">{{ $t('dashboard.delete') }}</span>
                        <icon-dots class="h-5 w-5"/>
                    </button>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-8 gap-6 mt-6">
            <div class="col-span-4">
                <input-normal v-model="option.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_prod')">
                    <template #title>{{ $t('dashboard.title') }}</template>
                </input-normal>
            </div>
            <div class="col-span-1 sm:col-span-2">
                <input-price v-model="deliveryPrice" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.money_placeholder')">
                <template #title>{{ $t('dashboard.delivery_extr_price') }}</template>
                </input-price>
            </div>
            <div class="col-span-1 sm:col-span-2">
                <input-price v-model="pickupPrice" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.money_placeholder')">
                <template #title>{{ $t('dashboard.pick-up_extr_price') }}</template>
                </input-price>
            </div>
            <div class="col-span-6">
            <div class="grid grid-cols-4 gap-5">
                <checkbox v-model="option.properties.vegetarian" checkboxname="Vegetarisch"><span class="text-base">{{ $t('dashboard.vegetarian') }}</span></checkbox>
                <checkbox v-model="option.properties.vegan" class="ml-10" checkboxname="Vegan"><span class="text-base">{{ $t('dashboard.vegan') }}</span></checkbox>
                <checkbox v-model="option.properties.glutenFree" class="ml-10" checkboxname="Glutenfrei"><span class="text-base">{{ $t('dashboard.glutenfree') }}</span></checkbox>
                <checkbox v-model="option.properties.basic" class="ml-10" checkboxname="Basisch"><span class="text-base">{{ $t('dashboard.basic') }}</span></checkbox>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { ProductOption } from 'delivery-boosting-2021-model'
import InputNormal from '../ui/InputNormal.vue'
import IconTrash from '../icons/IconTrash.vue'
import InputPrice from '../ui/InputPrice.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import IconDots from '@/components/icons/IconDots.vue'

export default {
    props: {
        option: {
            type: ProductOption
        },
        index: {
            type: Number
        }
    },
    components: {
        InputNormal,
        IconTrash,
        InputPrice,
        Checkbox,
        IconDots
    },
    computed: {
        deliveryPrice: {
            get () {
                return (this.option.getPriceByMethod('delivery') || { amount: 0 }).amount
            },
            set (val) {
                if (this.option.price.find(p => p.method === 'delivery')) {
                    this.option.getPriceByMethod('delivery').amount = val
                } else {
                    this.option.addPrice({
                        method: 'delivery',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        },
        pickupPrice: {
            get () {
                return (this.option.getPriceByMethod('pick-up') || { amount: 0 }).amount
            },
            set (val) {
                if (this.option.price.find(p => p.method === 'pick-up')) {
                    this.option.getPriceByMethod('pick-up').amount = val
                } else {
                    this.option.addPrice({
                        method: 'pick-up',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        }

    }
}
</script>

<style>

</style>
