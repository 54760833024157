<template>
  <div class="md:bg-white rounded-lg md:p-10">
    <h4 class="mb-5">Metafelder</h4>
    <div v-for="metafieldDefinition of metafieldDefinitionList.metafieldDefinitions" :key="metafieldDefinition.code" class="grid grid-cols-6 gap-6">
      <div class="col-span-4">
        <div>
          <label for="name" class="block text-sm font-bold">Name</label>
          <div class="relative mt-1">
            <h5>{{metafieldDefinition.name}}</h5>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <div>
          <label for="name" class="block text-sm font-bold">Typ</label>
          <div class="relative mt-1">
            {{metafieldDefinition.fieldType}}
          </div>
        </div>
      </div>
      <div class="col-span-6">
        <div>
          <label for="about" class="block text-sm font-bold"
            >Beschreibung</label
          >
          <div class="mt-1">
            {{metafieldDefinition.description}}
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div>
          <div class="relative mt-1">
            <input-normal v-if="metafieldDefinition.fieldType == 'text'" inputtype="text" inputname="name" inputid="name2" inputplaceholder="abcde">
                 <template #title>Input: Text</template>
            </input-normal>
            <label v-if="metafieldDefinition.fieldType == 'url'" for="url" class="block text-sm font-bold">Input: URL</label>
            <input v-if="metafieldDefinition.fieldType == 'url'" type="url" name="name" id="url" placeholder="URL" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300"/>
            <label v-if="metafieldDefinition.fieldType == 'color'" for="favcolor" class="block text-sm font-bold">Input: Color</label>
            <chrome-color
                v-if="metafieldDefinition.fieldType == 'color'"
                type="color"
                id="favcolor"
                name="favcolor"
                v-model="colorpick"
              />
            <label v-if="metafieldDefinition.fieldType == 'number'" for="number" class="block text-sm font-bold">Input: Number</label>
            <input v-if="metafieldDefinition.fieldType == 'number'" type="number" name="name" id="number" placeholder="10" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300"/>
            <toggle v-if="metafieldDefinition.fieldType == 'boolean'" class="mr-5 my-2"><span class="font-bold ml-4">Boolean</span></toggle>
            <input-measurement
                  v-if="metafieldDefinition.fieldType == 'measurement'"
                  inputname="name"
                  inputid="name"
                  inputplaceholder="100"
                >
                  <template #title>Input: Measurement</template>
                    <template #icon>
                        <span class="w-5 h-5 absolute top-1/2 transform right-12 -translate-y-1/2 text-black-400">
                        {{metafieldDefinition.measurementUnit}}
                        </span>
                    </template>
            </input-measurement>
            <datepicker-block v-if="metafieldDefinition.fieldType == 'timestamp'">
                        <template #icon>
                            <icon-calendar/>
                        </template>
            </datepicker-block>
          </div>
        </div>
      </div>
      <div class="col-span-6">
            <div v-if="metafieldDefinition.quantity == 'Mehrere Werte'" class="relative flex justify-center">
                <button type="button" class="inline-flex items-center px-4 py-1.5 border border-gray-300 text-sm leading-5 font-bold rounded-full text-gray-600 bg-white hover:bg-gray-50 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="-ml-1.5 mr-1 h-5 w-5 text-gray-500"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                        <span>Auswahl hinzufügen</span>
                </button>
            </div>
      </div>
      <div class=col-span-6>
      <div class="w-full border-t border-gray-300 mb-6"></div>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import InputNormal from '@/components/ui/InputNormal.vue'
import { Chrome } from 'vue-color'
import Toggle from '@/components/ui/Toggle.vue'
import DatepickerBlock from '@/components/ui/DatepickerBlock.vue'

import InputMeasurement from '../../components/ui/InputMeasurement.vue'
export default {
    name: 'MetafieldDefinition',
    components: {
        InputNormal,
        'chrome-color': Chrome,
        Toggle,
        InputMeasurement,
        DatepickerBlock
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    this.metafieldDefinitionList = await this.restaurant.getMetafieldDefinitionList()
                } catch (error) {
                    console.warn(error)
                }
            }
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    data () {
        return {
            metafieldDefinitionList: [],
            colorpick: {}
        }
    },
    methods: {
    }
}
</script>
