<template>
<div>
    <div>
        <label class="block text-sm font-bold mb-1">{{ $t('dashboard.status') }}</label>
        <multiselect
        v-model="state"
        @input="availability = product.availability"
        :options="[ 'ONLINE', 'OFFLINE', 'TIMED' ]"
        :placeholder="$t('dashboard.choose_status')"
        :deselectLabel="$t('dashboard.remove')"
        :selectedLabel="$t('dashboard.selected')"
        :selectLabel="$t('dashboard.select')"
        :multiple="false">
        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ $t(`dashboard.status_${option}`) }}</strong></template>
        <template slot="option" slot-scope="{ option }">{{ $t(`dashboard.status_${option}`) }}</template>
        </multiselect>
        <transition name="slide-fade">
        <status-timed v-show="state == 'TIMED'" :availability="availability"/>
        </transition>
        <label class="block text-sm font-bold mb-1 mt-5">{{ $t('dashboard.availability') }}</label>
    </div>
    <div>
        <multiselect
        v-model="available"
        @x-input="availability = product.availability"
        :options="[ 'ALWAYS', 'NEVER', 'TIMED' ]"
        :placeholder="$t('dashboard.choose_availability')"
        :deselectLabel="$t('dashboard.remove')"
        :selectedLabel="$t('dashboard.selected')"
        :selectLabel="$t('dashboard.select')"
        :multiple="false">
        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ $t(`dashboard.available_${option}`) }}</strong></template>
        <template slot="option" slot-scope="{ option }">{{ $t(`dashboard.available_${option}`) }}</template>
        </multiselect>
        <transition name="slide-fade">
        <time-window v-show="available == 'TIMED'" :availability="availability"/>
        </transition>
    </div>
    <div v-if="restaurant.locations.length > 1" class="mt-3">
        <label class="block text-sm font-bold mb-1">{{ $t('dashboard.locations_choose') }}</label>
        <multiselect
        v-model="locations"
        :options="restaurant.locations"
        track-by="uuid"
        label="generalTitle"
        :placeholder="'Standorte wählen'"
        :deselectLabel="$t('dashboard.remove')"
        :selectedLabel="$t('dashboard.selected')"
        :selectLabel="$t('dashboard.select')"
        :multiple="true">
        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.generalTitle }}</strong></template>
        <template slot="option" slot-scope="{ option }">{{ option.generalTitle }}</template>
        </multiselect>
    </div>
</div>
</template>

<script>
import TimeWindow from '@/components/dashboard/TimeWindow.vue'
import Multiselect from 'vue-multiselect'
import { Availability, Product } from 'delivery-boosting-2021-model'
import StatusTimed from '@/components/dashboard/StatusTimed.vue'

export default {
    components: { TimeWindow, Multiselect, StatusTimed },
    data () {
        return {
            valueAailable: '',
            valueStatus: '',
            i: true
        }
    },
    props: {
        availability: {
            type: Availability
        },
        product: {
            type: Product
        }
    },
    computed: {
        state: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.availability._data.state || ''
            },
            set (state) {
                this.availability.state = state
            }
        },
        available: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.availability._data.available || ''
            },
            set (available) {
                this.availability.available = available
            }
        },
        locations: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.availability.locations.map((location) => this.restaurant.locations.find(l => l.uuid === location) || {})
            },
            set (val) {
                // eslint-disable-next-line no-unused-expressions
                this.i
                this.availability._data.locations = val.map(location => location.uuid)
            }
        },
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    created () {
        setInterval(() => { this.i = !this.i; this.$forceUpdate() }, 100)
        if (!this.availability.state) {
            this.availability.state = 'ONLINE'
        }
        if (!this.availability.available) {
            this.availability.available = 'ALWAYS'
        }
        if (this.restaurant.locations.length === 1) {
            this.availability.locations.push(this.restaurant.locations[0].uuid)
        }
    },
    methods: {
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
